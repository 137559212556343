import React from "react";
import { useSelector } from "react-redux";

// Import Style
import "./index.scss";

// Import Components
import { MENU_ITEMS } from "../../../constants/menu-items";
import TabPaneItem from "../tab-pane-item";

function TabPane() {
  const closeTabPane = useSelector((store) => store.sideMenu);
  const { open = false, sidebarTitle = "dashboard" } = closeTabPane;

  return (
    <div className={open ? "tab-pane" : "tab-pane-closed"}>
      {MENU_ITEMS.map((item, key) => {
        return (
          <TabPaneItem
            items={item.id === sidebarTitle ? item.items : []}
            key={key}
          />
        );
      })}
    </div>
  );
}

export default TabPane;
