import DashboardIcon from "@material-ui/icons/Dashboard";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export const MENU_ITEMS = [
  {
    title: "داشبورد",
    id: "dashboard",
    icon: <DashboardIcon />,
    items: [
      { title: " 1 داشبورد", id: "dashboard1" },
      { title: " 2 داشبورد", id: "dashboard2" },
    ],
  },
  {
    title: "گزارشات",
    id: "reports",
    icon: <AssessmentIcon />,
    items: [
      { title: " 1 گزارش", id: "report1" },
      { title: " 2 گزارش", id: "report2" },
    ],
  },
  {
    title: "خروج",
    id: "logout",
    icon: <ExitToAppIcon />,
  },
];
