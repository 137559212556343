import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton, Avatar, Grid, Box } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
// import {tz} from "moment-timezone"

// Import Style
import "./index.scss";

function Topbar() {
  const closeTabPane = useSelector((store) => store.sideMenu);
  const { open = false } = closeTabPane;
  const dispatch = useDispatch();

  const [clock, setClock] = useState();
  const [date, setDate] = useState();

  useEffect(() => {
    setInterval(() => {
      setClock(moment().format("H:mm:s"));
    }, 1000);
    setDate(new Date().toLocaleDateString("fa-IR"));
  }, [clock]);

  return (
    <div className={open ? "topbar" : "topbar-extended"}>
      <Grid container alignItems="center">
        <Grid item xs={1} className="hamburger">
          <IconButton
            onClick={() => {
              dispatch({
                type: "COLLAPSE",
                payload: true,
              });
            }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1} className="avatar">
          <Avatar alt="user" variant="rounded">
            UN
          </Avatar>
        </Grid>
        <Grid item xs={3}>
          نام کاربر
        </Grid>
        <Grid item xs={3} className="date-time">
          <Box>{clock}</Box>
          <Box>{date}</Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Topbar;
