import React from "react";
import { IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

// Import Style
import "./index.scss";

// Import Components
import { MENU_ITEMS } from "../../constants/menu-items";

function MobileNavbar() {
  const sideMenu = useSelector((state) => state.sideMenu);
  const { open = false, sidebarTitle = "dashboard" } = sideMenu;
  const dispatch = useDispatch();
  return (
    <div className={open ? "mobile-navbar" : "hide"}>
      {MENU_ITEMS.map((item, key) => {
        return (
          <ul
            key={key}
            className={sidebarTitle === item.id ? "active-link" : ""}
            onClick={() => {
              dispatch({
                type: "CHOOSE_TITLE",
                payload: item.id,
              });
              dispatch({
                type: "COLLAPSE",
                payload: false,
              });
            }}
          >
            <IconButton>{item.icon}</IconButton>
            {item.title}
          </ul>
        );
      })}
    </div>
  );
}

export default MobileNavbar;
