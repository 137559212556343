import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, TextField, IconButton, Paper, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AccountCircle, Visibility, VisibilityOff } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

// Import Style
import "./index.scss";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // chnage route
  const route = useHistory();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("این فیلد الزامی است!"),
      password: Yup.string()
        .required("این فیلد الزامی است!")
        .min(8, "رمز عبور بیشتر از 8 کاراکتر است!")
        .max(32, "رمز عبور باید کمتر از 32 کاراکتر باشد"),
    }),
    onSubmit: (values) => {
      const { username = "", password = "" } = values;
      if (username === "aslankh" && password === "123456789") {
        route.push("/dashboard");
      } else {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      }
    },
  });
  const { handleChange, values, handleSubmit, errors, touched, handleBlur } =
    formik;
  return (
    <Grid container className="login-container">
      <Grid item lg={6} xs={12} className="fields-holder">
        <Paper elevation={3} className="login-paper">
          <h3>ورود کاربر</h3>
          <form noValidate onSubmit={handleSubmit}>
            {showAlert && (
              <Alert severity="error" onClose={() => setShowAlert(false)}>
                نام کاربری یا رمز عبور اشتباه است!
              </Alert>
            )}
            <Grid item lg={12} xs={12} className="username-field">
              <IconButton className="icon-button">
                <AccountCircle />
              </IconButton>
              <TextField
                id="username"
                label="نام کاربری"
                variant="outlined"
                value={values.username}
                onChange={handleChange("username")}
                onBlur={handleBlur}
                helperText={
                  touched.username && errors.username ? errors.username : ""
                }
                error={touched.username && errors.username ? true : false}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                className="icon-button"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <TextField
                id="password"
                label="رمز عبور"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                onBlur={handleBlur}
                helperText={
                  touched.password && errors.password ? errors.password : ""
                }
                error={touched.password && errors.password ? true : false}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Button
                variant="contained"
                className="login-button"
                color="primary"
                type="submit"
              >
                ورود
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item lg={6} xs={12} className="logo-holder">
        <h3> </h3>
      </Grid>
    </Grid>
  );
}

export default Login;
