import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistedStore } from "./redux/store";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// global theme setting for material ui
const theme = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      light: "#7986cb",
      main: "#000",
      dark: "#1a1400", // when a button clicks, this color applies
      contrastText: "#ffcc00",
    },
    // secondary,
    // error,
    // warning,
    // info,
    // success,
    contrastThreshold: 3,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StylesProvider jss={jss}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </StylesProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
