import React from "react";

function TabPaneItem(props) {
  const { items = [] } = props;

  return (
    <>
      {items.map((item, key) => {
        const { title = "" } = item;
        return <ul key={key}>{title}</ul>;
      })}
    </>
  );
}

export default TabPaneItem;
