import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Import Style
import "./index.scss";

function ParentContainer(props) {
  const closeTabPane = useSelector((store) => store.sideMenu);
  const { open = false } = closeTabPane;
  const dispatch = useDispatch();

  const handleCloseTabPane = () => {
    dispatch({
      type: "COLLAPSE",
      payload: false,
    });
  };

  return (
    <div
      className={open ? "parent-container" : "parent-container-extended"}
      onClick={handleCloseTabPane}
    >
      {props.children}
    </div>
  );
}

export default ParentContainer;
