const intialState = {
  open: false,
  sidebarTitle: "dashboard",
};

export const sideMenuReducer = (state = intialState, action) => {
  switch (action.type) {
    case "COLLAPSE":
      // login logic here
      return {
        ...state,
        open: Boolean(action.payload),
      };
    case "CHOOSE_TITLE":
      return {
        ...state,
        sidebarTitle: String(action.payload),
      };
    case "CHOOSE_MENU":
      return {
        ...state,
        open: Boolean(action.payload),
      };
    default:
      return state;
  }
};
