import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.scss";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
  );
}

export default App;
