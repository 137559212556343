import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

// Import Components
import { MENU_ITEMS } from "../../constants/menu-items";
import TabPane from "./tab-pane";

// Import Style
import "./index.scss";

function DesktopNavbar() {
  const closeTabPane = useSelector((store) => store.sideMenu);
  const { open = false, sidebarTitle = "dashboard" } = closeTabPane;
  const dispatch = useDispatch();

  const handleOpenTabPane = (id) => {
    if (id === "logout") {
      dispatch({
        type: "CHOOSE_TITLE",
        payload: id,
      });
      dispatch({
        type: "COLLAPSE",
        payload: false,
      });
    } else {
      dispatch({
        type: "CHOOSE_TITLE",
        payload: id,
      });
      dispatch({
        type: "COLLAPSE",
        payload: true,
      });
    }
  };
  return (
    <>
      <div className={open ? "desktop-navbar" : "desktop-navbar-closed"}>
        {MENU_ITEMS.map((item, key) => {
          return (
            <ul
              key={key}
              id={item.id}
              className={
                sidebarTitle === item.id
                  ? "desktop-nav-item-active"
                  : "desktop-nav-item"
              }
              onClick={() => handleOpenTabPane(item.id)}
            >
              <Tooltip arrow placement="left" title={item.title}>
                <IconButton>{item.icon}</IconButton>
              </Tooltip>
            </ul>
          );
        })}
      </div>
      <TabPane />
    </>
  );
}

export default DesktopNavbar;
