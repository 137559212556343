import React from "react";

// Import Style
import "./index.scss";

// Import Components
import Topbar from "../topbar";
import DesktopNavbar from "../desktop-navbar/index";
import MobileNavBar from "../mobile-navbar/index";
import ParentContainer from "../parent-container";

function MainLayOut(props) {
  return (
    <div className="main-layout">
      <Topbar />
      <MobileNavBar />
      <DesktopNavbar />
      <ParentContainer>{props.children}</ParentContainer>
    </div>
  );
}

export default MainLayOut;
