import React from "react";
import { Paper } from "@material-ui/core";

// Import Components
import MainLayOut from "../../components/main-layout";

function Dashboard() {
  return (
    <MainLayOut>
      <Paper>dashboard</Paper>
    </MainLayOut>
  );
}

export default Dashboard;
